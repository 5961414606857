import React, { useState, useEffect, useCallback, Suspense, useRef } from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup, Card } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { tokens } from "../../../../theme";
import {
  CashFlowProjectionData,
  PropertyDetailsData,
  KeyAssumptionsData,
  PIScenarioData,
  IOScenarioData,
  CashFlowYearData,
  AcquisitionCostsData,
} from "../../../../types/CashFlow";
// import CashFlowScenario from "../cashFlowScenario";
import KeyAssumptions from "../keyAssumptions";
import { updateCashFlowProjection, updateKeyAssumptions } from "../../../../features/cashFlow";
import { calculateTaxWithDetails, calculateTransferFee, formatNumberWithCommas, State, vacantPerioudGrossRent } from "../../../../hooks/properties/properties";

const CashFlowScenario = React.lazy(() => import('../cashFlowScenario'));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(1.5, 3),
  border: `2px solid ${theme.palette.primary.main}`,
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface CashFlowProjectionProps {
  propertyDetails: PropertyDetailsData | null;
  keyAssumptions?: KeyAssumptionsData | null;
  cashFlowProjection: CashFlowProjectionData | null;
  graphs?: any
  // updateCashFlowProjections: (projection: CashFlowProjectionData) => void;
}

const CashFlowProjection: React.FC<CashFlowProjectionProps> = ({
  propertyDetails,
  keyAssumptions,
  cashFlowProjection,
  graphs
  // updateCashFlowProjections,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedScenario, setSelectedScenario] = useState<"scenario1" | "scenario2">("scenario2");
  // const [selectedScenario, setSelectedScenario] = useState('scenario1');
  const dispatch = useDispatch();
  const cashFlowAnalysis = useSelector((state: RootState) => state.cashFlowAnalysis);
  console.log('cashFlowAnalysis000000', cashFlowAnalysis)
  const [isLoading, setIsLoading] = useState(false);

  const handleScenarioChange = (newScenario: "scenario1" | "scenario2") => {
    setIsLoading(true)
    setSelectedScenario(newScenario);
    setTimeout(() => {

      setIsLoading(false); // Stop loading once data is fetched
      // if (propertyDetails && keyAssumptions && cashFlowProjection) {

      cashFlowProjectionHandlerCallback();
      // }
    }, 1000);
  };

  // useEffect(() => {
  //   if (!selectedScenario) return;
  // }, [selectedScenario]);
  const calculatePrincipalInterestRepayment = useCallback((loanAmount: number, annualInterestRate: number, loanTermYears: number) => {
    // 1. Input validation with clean numbers
    const cleanLoanAmount = Number(loanAmount);
    const cleanInterestRate = Number(annualInterestRate);
    const cleanLoanTerm = Number(loanTermYears);

    if (!cleanLoanAmount || !cleanInterestRate || !cleanLoanTerm) {
      console.warn('Invalid inputs:', { loanAmount, annualInterestRate, loanTermYears });
      return {
        monthlyPayment: 0,
        yearlySchedule: Array(30).fill({
          year: 0,
          annualRepayment: 0,
          annualPrincipal: 0,
          annualInterest: 0,
          remainingBalance: cleanLoanAmount || 0
        }),
        annualPayment: 0
      };
    }

    // 2. Calculate monthly rate and payments
    const monthlyRate = cleanInterestRate / 100 / 12;
    const totalPayments = cleanLoanTerm * 12;

    // 3. Calculate exact monthly payment
    const monthlyPayment = (cleanLoanAmount * monthlyRate * Math.pow(1 + monthlyRate, totalPayments)) / 
                          (Math.pow(1 + monthlyRate, totalPayments) - 1);

    let balance = cleanLoanAmount;
    const yearlyData = [];

    // 4. Calculate year-by-year amortization
    for (let year = 1; year <= 30; year++) {
      let yearlyPrincipal = 0;
      let yearlyInterest = 0;

      // Only calculate for years within loan term
      if (year <= cleanLoanTerm) {
        // Calculate monthly for accuracy
        for (let month = 1; month <= 12; month++) {
          const monthlyInterest = balance * monthlyRate;
          const monthlyPrincipal = monthlyPayment - monthlyInterest;

          yearlyInterest += monthlyInterest;
          yearlyPrincipal += monthlyPrincipal;
          
          // For the very last payment, pay off any remaining balance
          if (year === cleanLoanTerm && month === 12) {
            yearlyPrincipal += balance - monthlyPrincipal;
            balance = 0;
          } else {
            balance = Math.max(0, balance - monthlyPrincipal);
          }
        }
      }

      yearlyData.push({
        year,
        annualRepayment: Math.round(monthlyPayment * 12),
        annualPrincipal: Math.round(yearlyPrincipal),
        annualInterest: Math.round(yearlyInterest),
        remainingBalance: year === cleanLoanTerm ? 0 : Math.round(Math.max(0, balance)) // Force 0 at end of term
      });
    }

    return {
      monthlyPayment: Math.round(monthlyPayment * 100) / 100,
      yearlySchedule: yearlyData,
      annualPayment: Math.round(monthlyPayment * 12)
    };
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const calculatePrincipalInterestRepayment = (
  //   loanAmount: number,
  //   annualInterestRate: number,
  //   currentYear: number,
  //   loanTermYears: number = 30
  // ): {
  //   beginningBalance: number,
  //   annualPayment: number,
  //   principalPaid: number,
  //   interestPaid: number,
  //   remainingBalance: number
  // } => {
  //   // Validate input
  //   if (currentYear < 1 || currentYear > loanTermYears) {
  //     throw new Error(`Current year must be between 1 and ${loanTermYears}`);
  //   }

  //   // Convert annual interest rate to decimal
  //   const interestRate = annualInterestRate / 100;

  //   // Calculate annual payment using the standard amortization formula
  //   const annualPayment = loanAmount *
  //     (interestRate * Math.pow(1 + interestRate, loanTermYears)) /
  //     (Math.pow(1 + interestRate, loanTermYears) - 1);

  //   // Calculate beginning balance for the current year
  //   let beginningBalance = loanAmount;
  //   for (let year = 1; year < currentYear; year++) {
  //     const yearInterestPaid = beginningBalance * interestRate;
  //     const yearPrincipalPaid = annualPayment - yearInterestPaid;
  //     beginningBalance -= yearPrincipalPaid;
  //   }

  //   // Calculate interest and principal for the current year
  //   const interestPaid = beginningBalance * interestRate;
  //   const principalPaid = annualPayment - interestPaid;
  //   const remainingBalance = beginningBalance - principalPaid;

  //   return {
  //     beginningBalance: Number(beginningBalance.toFixed(2)),
  //     annualPayment: Number(annualPayment.toFixed(2)),
  //     principalPaid: Number(principalPaid.toFixed(2)),
  //     interestPaid: Number(interestPaid.toFixed(2)),
  //     remainingBalance: Number(remainingBalance.toFixed(2))
  //   };
  // }

  // Example usage in a handler loop



  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateInterestOnlyRepayment = (
    (loanAmount: number, interestRate: number): number => {

      // const monthlyInterestRate = interestRate / 100 / 12;
      // // const monthlyInterestRate = interestRate / 100 ;
      // // const interestPayment = loanAmount * monthlyInterestRate;
      // const interestPayment = loanAmount * monthlyInterestRate;
      const annualInterestRate = interestRate / 100;
      const interestPayment = loanAmount * annualInterestRate;
      console.log('interestPayment :>> ', interestPayment);
      return interestPayment;
    }
  );


  const generateCashFlowYearsData = useCallback(
    (
      propertyDetails: PropertyDetailsData,
      keyAssumptions: KeyAssumptionsData,
      principalInterestRepayment: number,
      interestOnlyRepayment: number,
      scenario: "scenario1" | "scenario2"
    ): CashFlowYearData[] => {

      const { estimatedWeeklyRent, annualHoldingCosts, propertyValue, loanAmount, interestRate, loanTerm } = propertyDetails;
      const { capitalGrowthRate, rentalGrowthRate, inflationRate, vacancyPeriod } = keyAssumptions;
      const PMFofWR = (((annualHoldingCosts.propertyManagementFee / 100) * estimatedWeeklyRent)) * vacantPerioudGrossRent(vacancyPeriod)

      // const YearInterestRate = cashFlowProjection?.scenario2.cashFlowYears.map(year => year.InterestRate)

      console.log('PMFofWR', PMFofWR)
      const LFofWR = annualHoldingCosts.lettingFee * estimatedWeeklyRent;
      const cashFlowYears: CashFlowYearData[] = [];
      // const previousInterestRate = propertyDetails.interestRate !== interestRate ? interestRate : 0
      // 
      // console.log('previousInterestRate', previousInterestRate)
      let currentPropertyValue = propertyValue;
      let currentLoanAmount = loanAmount;
      let currentRent = estimatedWeeklyRent * vacantPerioudGrossRent(vacancyPeriod);
      let currentYear = 1;
      let YearlyInterestRates_With_Year: any[] = [];
      let NewlyYearlyInterestRates_With_Year: any[] = [];
      let grossRent = currentRent * 52; // For the first year, just multiply by 52


      let currentRentalExpenses =
        (PMFofWR + LFofWR +
          annualHoldingCosts.maintenanceCostPA +
          annualHoldingCosts.councilRates +
          annualHoldingCosts.waterRates +
          annualHoldingCosts.insurance +
          annualHoldingCosts.miscellaneous_costs)
      YearlyInterestRates_With_Year = [...NewlyYearlyInterestRates_With_Year];

      NewlyYearlyInterestRates_With_Year = cashFlowProjection?.scenario1?.cashFlowYears?.map((yearData) => ({
        year: yearData.year,
        interestRate: yearData.InterestRate,
        loanAmount: yearData.loanAmount
      })) || [];

      console.log('Previous Yearly Interest Rates:', YearlyInterestRates_With_Year);
      console.log('Newly Mapped Yearly Interest Rates:', NewlyYearlyInterestRates_With_Year);

      // Function to find the first difference
      // Function to find where interestRate changes and persists
      const findInterestRateChange = (arr: any[]) => {
        const changes = [];

        for (let i = 0; i < arr.length - 1; i++) {
          // If the interest rate changes
          if (arr[i].interestRate !== arr[i + 1].interestRate) {
            // Replace the last change with the new change
            if (changes.length > 0) {
              changes[changes.length - 1] = {
                year: arr[i + 1].year,  // Year of the change
                interestRate: arr[i + 1].interestRate, // New interest rate
                loanAmount: arr[i + 1].loanAmount  // New LoanAmount
              };
            } else {
              // Store the first change
              changes.push({
                year: arr[i + 1].year,
                interestRate: arr[i + 1].interestRate,
                loanAmount: arr[i + 1].loanAmount  // New LoanAmount
              });
            }
          }
        }

        return changes;
      };

      // Get all interest rate changes
      const interestRateChanges = findInterestRateChange(NewlyYearlyInterestRates_With_Year);

      console.log('Interest Rate Changes:', interestRateChanges.length);
      const InterestRate = scenario === "scenario1" ? cashFlowProjection?.scenario1.cashFlowYears[currentYear - 1].InterestRate : scenario === "scenario2" ? cashFlowProjection?.scenario2?.cashFlowYears[currentYear - 1].InterestRate : interestRate || interestRate

      const PrincipalInterestrepayment = calculatePrincipalInterestRepayment(
        currentLoanAmount,
        // interestRateChanges.length > 0 ? interestRateChanges[0]?.loanAmount : currentLoanAmount,
        interestRateChanges.length > 0 ? interestRateChanges[0]?.interestRate : InterestRate,
        loanTerm
      )



      for (let year = 1; year <= 30; year++) {
        currentYear = year;
        // Property Value
        if (year > 1) {

          grossRent *= 1 + (rentalGrowthRate / 100);
        }
        if (year > 0) {
          currentPropertyValue *= 1 + capitalGrowthRate / 100;


        }
        // Loan Amount  `
        if (year === 1) {

          // currentLoanAmount = loanAmount;
          grossRent = currentRent

        }
        // if (selectedScenario === "scenario1" && year > 1) {
        //   currentLoanAmount = currentLoanAmount + cashFlowProjection?.scenario1.cashFlowYears[year - 1].ExtraPrincipalRepayment;

        // }


        // const YearInterestRate = interestRate !== 0 ? interestRate : cashFlowProjection?.scenario2.cashFlowYears[year - 1].InterestRate;
        // const currentYearLoanAmount = scenario === "scenario1" && isNaN(Number(cashFlowAnalysis?.cashFlowProjection?.scenario1?.cashFlowYears[year - 1]?.loanAmount)) ? 0 : cashFlowAnalysis?.cashFlowProjection?.scenario1?.cashFlowYears[year - 1]?.loanAmount !== 0 ? cashFlowAnalysis?.cashFlowProjection?.scenario1?.cashFlowYears[year - 1]?.loanAmount : loanAmount || loanAmount

        const YearInterestRate = scenario === "scenario1" ? cashFlowProjection?.scenario1.cashFlowYears[year - 1].InterestRate : scenario === "scenario2" ? cashFlowProjection?.scenario2?.cashFlowYears[year - 1].InterestRate : interestRate || interestRate

        // currentLoanAmount = cashFlowProjection?.scenario1.cashFlowYears[year - 1].loanAmount ? cashFlowProjection?.scenario1.cashFlowYears[year - 1].loanAmount : 0
        // const PrincipalInterestrepayment = calculatePrincipalInterestRepayment(isNaN(currentLoanAmount) ? 0 : currentLoanAmount, (YearInterestRate || 0), (loanTerm));

        const principalForYear = PrincipalInterestrepayment?.yearlySchedule?.find(
          schedule => schedule.year === year
        )?.annualPrincipal ?? 0;
        // const principalForYear = PrincipalInterestrepayment.principalPaid;
        // const anuualInterestForYear = PrincipalInterestrepayment.yearlySchedule.find(schedule => schedule.year === year)?.annualInterest;

        // } else {
        if (year > 1 && scenario === "scenario1") {
          const previousYearExtraPayment = cashFlowProjection?.scenario1.cashFlowYears[year - 2].ExtraPrincipalRepayment;
          if (previousYearExtraPayment !== 0) {
            currentLoanAmount = currentLoanAmount - Number(previousYearExtraPayment);
          }
        }
        if (scenario === "scenario1" && year > 0) {
          // Get principal payment from amortization schedule
          const principalForYear = PrincipalInterestrepayment.yearlySchedule.find(
            schedule => schedule.year === year
          )?.annualPrincipal ?? 0;

          // Simply use the remaining balance from amortization schedule
          currentLoanAmount = PrincipalInterestrepayment.yearlySchedule.find(
            schedule => schedule.year === year
          )?.remainingBalance ?? 0;

          // Apply any extra principal repayment if exists
          if (year > 1) {
            const previousYearExtraPayment = cashFlowProjection?.scenario1.cashFlowYears[year - 2].ExtraPrincipalRepayment;
            if (previousYearExtraPayment !== 0) {
              currentLoanAmount = Math.max(0, currentLoanAmount - Number(previousYearExtraPayment));
            }
          }
        } else {

          // Interest Only scenario
          currentLoanAmount = loanAmount;
        }

        // }

        // Equity
        const equity = currentPropertyValue - (scenario === "scenario1" ? currentLoanAmount : loanAmount);

        // Update values for the next year
        currentRent *= 1 + rentalGrowthRate / 100;
        if (year > 1) {

          currentRentalExpenses *= 1 + inflationRate / 100;
          // if (scenario === "scenario1" && cashFlowProjection?.scenario1.cashFlowYears[year - 1].ExtraPrincipalRepayment !== 0 && Number(cashFlowProjection?.scenario1.cashFlowYears[year - 1]) === Number(year)) {

          //   currentLoanAmount = currentLoanAmount + cashFlowProjection?.scenario1.cashFlowYears[year - 1].ExtraPrincipalRepayment;
          // }
        }

        // Gross Rent
        // grossRent = currentRent;  


        const PrincipleinterestCharged = Math.round(((YearInterestRate || 0) / 100) * (scenario === "scenario1" ? currentLoanAmount : loanAmount) * 100) / 100;

        // Rental Expenses
        const rentalExpenses = currentRentalExpenses;

        let beforeTaxCashFlow = 0
        // Before Tax Cash Flow
        beforeTaxCashFlow = scenario === "scenario1" ? grossRent - rentalExpenses - PrincipleinterestCharged - Number(principalForYear) : grossRent - rentalExpenses - PrincipleinterestCharged


        // grossRent -
        // rentalExpenses -
        // (scenario === "scenario1" ? PrincipleinterestCharged : ((YearInterestRate || 0) / 100));

        // calculate Aust tax rate
        const taxRate = calculateTaxWithDetails(Number(cashFlowAnalysis.employmentIncome?.grossFamilyIncome) + Number(cashFlowAnalysis.employmentIncome?.otherTaxableIncome))

        // After Tax Cash Flow
        const depreciation = cashFlowAnalysis.depreciation.depreciation;
        // const refund = (beforeTaxCashFlow + (- depreciation)) * Number(taxRate.effectiveRate)
        // const afterTaxCashFlow = beforeTaxCashFlow - refund
        // Assuming beforeTaxCashFlow, depreciation, and taxRate.taxSlab are already defined

        const refund = isNaN((beforeTaxCashFlow + (-depreciation)) * (Number(taxRate.taxSlab) / 100))
          ? 0 // Return 0 if the result is NaN
          : (beforeTaxCashFlow + (-depreciation)) * (Number(taxRate.taxSlab) / 100);



        const afterTaxCashFlow = isNaN(beforeTaxCashFlow - refund)
          ? 0
          : beforeTaxCashFlow - refund;


        // Cost/Income pw
        const costIncomePw = beforeTaxCashFlow / vacantPerioudGrossRent(vacancyPeriod);

        const PrincipalInterestRepayment = principalForYear
        const extraPrincipalRepayment = cashFlowAnalysis.cashFlowProjection ? cashFlowAnalysis.cashFlowProjection?.scenario1.cashFlowYears?.find(principal => principal.year === year)?.ExtraPrincipalRepayment : 0

        const InterestOnlyRepayment = calculateInterestOnlyRepayment(currentLoanAmount, (YearInterestRate || 0));
        // const interestCharged = ((YearInterestRate / 100) * currentLoanAmount)

        const interestCharged = scenario === "scenario2" 
          ? Math.round(((YearInterestRate || 0) / 100) * loanAmount * 100) / 100 
          : PrincipalInterestrepayment?.yearlySchedule?.find(
              schedule => schedule.year === year
            )?.annualInterest ?? 0;
        // const interestCharged = Number(anuualInterestForYear?.toFixed(0))

        // const interestCharged = scenario === "scenario2"
        //   ? (YearInterestRate / 100) * loanAmount
        //   : (YearInterestRate / 100) * currentLoanAmount;

        cashFlowYears.push({
          propertyValue: currentPropertyValue,
          loanAmount: scenario === "scenario2" 
            ? propertyDetails.loanAmount 
            : currentLoanAmount,
          PrincipalInterestRepayment: Number(PrincipalInterestRepayment),
          InterestOnlyRepayment,
          ExtraPrincipalRepayment: scenario === "scenario2" ? 0 : Number(extraPrincipalRepayment),
          year,
          equity,
          grossRent,
          rentalExpenses,
          beforeTaxCashFlow,
          afterTaxCashFlow: afterTaxCashFlow,
          InterestRate: (YearInterestRate || 0),
          InterestCharged: interestCharged,
          costIncomePw,
        });


      }

      return cashFlowYears;
    },
    [cashFlowProjection?.scenario2?.cashFlowYears, calculatePrincipalInterestRepayment, cashFlowAnalysis.employmentIncome?.grossFamilyIncome, cashFlowAnalysis.employmentIncome?.otherTaxableIncome, cashFlowAnalysis.depreciation.depreciation, cashFlowAnalysis.cashFlowProjection]
  );


  const calculateCashFlowProjection = useCallback(
    (propertyDetails: PropertyDetailsData, keyAssumptions: KeyAssumptionsData): CashFlowProjectionData => {
      const { loanAmount, interestRate, loanTerm } = propertyDetails;

      // Calculate repayment values
      const principalInterestRepayment = calculatePrincipalInterestRepayment(isNaN(loanAmount) ? 0 : loanAmount, interestRate, loanTerm);
      const interestOnlyRepayment = calculateInterestOnlyRepayment(loanAmount, interestRate);
      const cash = cashFlowAnalysis
      console.log('cashFlowAnalysis', cashFlowAnalysis)

      // Generate cash flow years for Scenario 1
      const scenario1CashFlowYears = generateCashFlowYearsData(
        propertyDetails,
        keyAssumptions,
        Number(principalInterestRepayment),
        interestOnlyRepayment,
        "scenario1"
      ).map((yearData) => ({
        ...yearData,

      }));

      // Generate cash flow years for Scenario 2 with a fixed loan amount each year
      const scenario2CashFlowYears = generateCashFlowYearsData(
        { ...propertyDetails, loanAmount }, // Ensure the initial loanAmount is passed in
        keyAssumptions,
        Number(principalInterestRepayment),
        interestOnlyRepayment,
        "scenario2",
      ).map((yearData) => ({
        ...yearData,
        loanAmount: propertyDetails.loanAmount, // Fix loan amount for every year
      }));

      const scenario1: PIScenarioData = {
        principalInterestRepayment: Number(principalInterestRepayment),
        cashFlowYears: scenario1CashFlowYears,
      };

      const scenario2: IOScenarioData = {
        InterestOnlyRepayment: interestOnlyRepayment,
        cashFlowYears: scenario2CashFlowYears,
      };

      return {
        assumptions: keyAssumptions,
        scenario1,
        scenario2,
      };
    },
    [calculatePrincipalInterestRepayment, cashFlowAnalysis, generateCashFlowYearsData]
  );
  function calculateTotalAcquisitionCost(acquisitionCosts: AcquisitionCostsData) {
    const registrationFee = calculateTransferFee(cashFlowAnalysis.propertyDetails?.state as unknown as State, Number(cashFlowAnalysis.propertyDetails?.propertyValue))

    // const totalAcquisitionCost = Object.values(acquisitionCosts).reduce((total: number, value: number) => total + value || 0) + Number(registrationFee);
    const totalAcquisitionCost = Object.values(acquisitionCosts).reduce((total, value) => total + (typeof value === 'string' ? Number(value) : value) || 0) + Number(registrationFee);
    return { totalAcquisitionCost };
  }


  // Utility function to compare objects for equality
  const areObjectsEqual = useCallback((obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cashFlowProjectionHandler = useCallback(() => {
    if (propertyDetails && keyAssumptions) {
      const projection = calculateCashFlowProjection(propertyDetails, keyAssumptions);
      // Check if the new projection is different from the existing one

      if (!areObjectsEqual(projection, cashFlowProjection)) {
        dispatch(updateCashFlowProjection(projection));
      }
    }
  }, [propertyDetails, keyAssumptions, calculateCashFlowProjection, areObjectsEqual, cashFlowProjection, dispatch]);
  // }, [propertyDetails, keyAssumptions, selectedScenario, cashFlowProjection, calculateCashFlowProjection, areObjectsEqual, dispatch, cashFlowAnalysis?.depreciation?.depreciation, cashFlowAnalysis?.cashFlowProjection?.scenario1]);



  const cashFlowProjectionHandlerCallback = useCallback(() => {
    cashFlowProjectionHandler();
  }, [cashFlowProjectionHandler]);

  useEffect(() => {
    cashFlowProjectionHandlerCallback();
  }, [cashFlowAnalysis.propertyDetails, cashFlowProjection, keyAssumptions, cashFlowProjectionHandlerCallback]);

  return (
    <Box id='myChart' >
      <Card
        sx={{
          padding: "18px 20px 20px 20px !important",
          marginBottom: "30px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
          backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
        }}>
        <Box mb={4}>
          <KeyAssumptions
            keyAssumptions={cashFlowAnalysis.keyAssumptions}
            updateKeyAssumptions={(updates) =>
              dispatch(updateKeyAssumptions(updates))
            }
          />
        </Box>

        <Box display="flex" justifyContent="start">
          <ToggleButtonGroup
            value={selectedScenario}
            exclusive
            onChange={() => handleScenarioChange}
            sx={{ gap: 2 }}
          >
            <StyledToggleButton
              value="scenario2"
              className={selectedScenario === "scenario2" ? "btn_styleTwo active" : "btn_styleTwo"}
              onClick={() => {
                handleScenarioChange("scenario2");
                // Remove "active" class from the other button
                document?.querySelector(".btn_styleOne")?.classList?.remove("active");
              }}
              sx={{
                backgroundColor: selectedScenario === "scenario2" ? "#5c59fd" : colors.primary[900],
                borderColor: colors.primary[400],
                color: selectedScenario === "scenario2" ? "#fff" : "#4cceac",
                transition: "background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease", // Smooth transition for styles
                cursor: "pointer",
              }}
              disabled={selectedScenario === "scenario2"}
            >
              Interest Only
            </StyledToggleButton>
            <StyledToggleButton
              value="scenario1"
              className={selectedScenario === "scenario1" ? "btn_styleOne active" : "btn_styleOne"}
              onClick={() => {
                handleScenarioChange("scenario1");
                // Remove "active" class from the other button
                document.querySelector(".btn_styleTwo")?.classList.remove("active");
              }}
              sx={{
                backgroundColor: selectedScenario === "scenario1" ? "#5c59fd" : colors.primary[900],
                borderColor: colors.primary[400],
                color: selectedScenario === "scenario1" ? "#fff" : "#4cceac",
                transition: "background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease", // Smooth transition for styles
                cursor: "pointer",
              }}
              disabled={selectedScenario === "scenario1"}
            >
              Principal & Interest
            </StyledToggleButton>


          </ToggleButtonGroup>
        </Box>
      </Card>
      {isLoading ? (
        <Shimmer /> // Show the custom loader during transition
      ) : selectedScenario === "scenario1" && cashFlowProjection?.scenario1 ? (
        <Suspense fallback={<Shimmer />}>
          <CashFlowScenario
            title="Principal & Interest Loan"
            scenario={cashFlowProjection.scenario1}
            propertyDetails={cashFlowAnalysis ? cashFlowAnalysis.propertyDetails as any : {}}
            acquisationCosts={cashFlowAnalysis.propertyDetails?.acquisitionCosts ? calculateTotalAcquisitionCost(cashFlowAnalysis.propertyDetails?.acquisitionCosts).totalAcquisitionCost : 0}
            loanType="principal-interest"
          />
        </Suspense>
      ) : selectedScenario === "scenario2" && cashFlowProjection?.scenario2 ? (
        <Suspense fallback={<Shimmer />}>
          <CashFlowScenario
            title="Interest Only Loan"
            scenario={cashFlowProjection.scenario2}
            propertyDetails={cashFlowAnalysis ? cashFlowAnalysis.propertyDetails as any : {}}
            acquisationCosts={cashFlowAnalysis.propertyDetails?.acquisitionCosts ? calculateTotalAcquisitionCost(cashFlowAnalysis.propertyDetails?.acquisitionCosts).totalAcquisitionCost : 0}
            loanType="interest-only"
          />
        </Suspense>
      ) : (
        <Typography>No data available for the selected scenario</Typography>
      )}
    </Box>
  );
};

export default CashFlowProjection;
// Loader.js

const Loader = () => {
  const loaderStyle: any = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  };

  const spinningCircleStyle = {
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #3498db',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  };

  const textStyle = {
    marginTop: '10px',
    fontSize: '16px',
    color: '#3498db',
  };

  // Keyframes for the spinner animation
  const keyframesStyle = {
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    }
  };

  // Inject keyframes style into the document
  const styleSheet = document.styleSheets[0];
  const keyframe = `@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }`;
  styleSheet.insertRule(keyframe, styleSheet.cssRules.length);

  return (
    <div style={loaderStyle}>
      <div style={spinningCircleStyle}></div>
      <p style={textStyle}>Loading...</p>
    </div>
  );
};


const Shimmer = () => {
  return (
    <>
      <style>
        {`
          @keyframes shimmer {
            0% {
              background-position: -200% 0;
            }
            100% {
              background-position: 200% 0;
            }
          }
        `}
      </style>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
      </div>
    </>
  );
};
